'use client'

import AOS from 'aos'
import 'aos/dist/aos.css'

import Landing from '@/src/components/landing'

import LandingData from '@/public/content/landing_data'
import ProductsData from '@/public/content/products_data'
import StatisticsData from '@/public/content/statistics_data'
import AboutData from '@/public/content/about_data'
import Products from '@/src/components/products'
import StatisticsSection from '@/src/components/statistics'
import AboutSection from '@/src/components/about'
import { useEffect } from 'react'
import ContactUs from '@/src/components/contact-us'

import Analytics from 'analytics'
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager'

const analytics = Analytics({
  app: 'gambly-ai',
  plugins: [
    googleTagManager({
      containerId: 'G-3BQ4316GMP'
    })
  ]
})

export default function Home() {
  const landingData = LandingData
  const productsData = ProductsData
  const statisticsData = StatisticsData
  const aboutData = AboutData

  useEffect(() => {
    AOS.init()
    analytics.page()
  }, [])

  return (
    <main className="flex flex-col items-center justify-between md:overflow-visible relative bg-black z-20 pt-36 pb-12 overflow-x-hidden">
      <div className="w-full space-y-[10vh]">
        <Landing
          title={landingData.title}
          description={landingData.description}
          backgroundImage={landingData.backgroundImage}
        />
        <Products
          title={productsData.title}
          description={productsData.description}
          products={productsData.products}
        />
        <StatisticsSection
          title={statisticsData.title}
          description={statisticsData.description}
          stats={statisticsData.stats}
        />
        <AboutSection data={aboutData} />
        <ContactUs />
      </div>
      {/* <Carousel companies={clientsData} /> */}
      {/* <AboutUs
        title={aboutUsData.title}
        subtitle={aboutUsData.subtitle}
        description={aboutUsData.description}
        services={aboutUsData.services}
        backgroundImage={aboutUsData.backgroundImage}
      /> */}
      {/* <ContactUs offices={offices} /> */}
      <div
        className="orange-gradient"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1
        }}
      />
    </main>
  )
}
