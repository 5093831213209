import React from 'react'

import CategoryTitle from '../category-title'
import CategoryDescription from '../category-description'

interface Props {}

const ContactUs: React.FC<Props> = () => (
  <div className="flex flex-col container w-full md:w-2/3 contact-us mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="text-neutral w-full h-full relative p-6 border border-white/10 md:rounded-l-lg z-30 bg-gradient-to-b from-white/10 to-black/10">
        <CategoryTitle className="text-2xl text-white text-left">Contact Us</CategoryTitle>
        <CategoryDescription className="text-sm text-white text-left">
          We are here to assist you with all your business needs. Our dedicated team is ready to
          provide you with the support and expertise you require to achieve your goals.
        </CategoryDescription>
      </div>
      <div className="flex flex-col gap-9 w-full h-full">
        <div className="border md:border-l-0 border-white/10 bg-gradient-to-b from-black/30 to-black/50 shadow-default p-6 h-full rounded-r-lg">
          <div className="border-b border-white/10 py-4 px-6.5">
            <h3 className="font-medium text-white">Contact Form</h3>
          </div>
          <form
            className="text-white"
            action="https://getform.io/f/5ac754d4-64ef-4c16-bf35-4e6219781be9"
            method="POST"
          >
            <div className="p-6.5">
              <div className="mb-4.5 flex flex-col xl:gap-6 xl:flex-row">
                <div className="w-full xl:w-1/2">
                  <label className="mt-2 mb-1 block text-white text-sm">First name</label>
                  <input
                    type="text"
                    name="first_name"
                    placeholder="Enter your first name"
                    className="w-full rounded border border-white/10 bg-white/10 focus:bg-white/20 py-3 px-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter font-light text-xs"
                  />
                </div>

                <div className="w-full xl:w-1/2">
                  <label className="mt-2 mb-1 block text-white text-sm">Last name</label>
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Enter your last name"
                    className="w-full rounded border border-white/10 bg-white/10 focus:bg-white/20 py-3 px-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter font-light text-xs"
                  />
                </div>
              </div>

              <div className="mb-4.5">
                <label className="mt-2 mb-1 block text-white text-sm">Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  className="w-full rounded border border-white/10 bg-white/10 focus:bg-white/20 py-3 px-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter font-light text-xs"
                />
              </div>

              <div className="mb-4.5">
                <label className="mt-2 mb-1 block text-white text-sm">Subject</label>
                <input
                  type="text"
                  name="subject"
                  placeholder="Select subject"
                  className="w-full rounded border border-white/10 bg-white/10 focus:bg-white/20 py-3 px-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter font-light text-xs"
                />
              </div>

              <div className="mb-6">
                <label className="mt-2 mb-1 block text-white text-sm">Message</label>
                <textarea
                  rows={6}
                  name="message"
                  placeholder="Type your message"
                  className="w-full rounded border border-white/10 bg-white/10 focus:bg-white/20 py-3 px-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter font-light text-xs"
                ></textarea>
              </div>

              <button className="flex w-full justify-center rounded transition-all duration-500 bg-accent p-3 text-neutral hover:scale-105 bg-gradient-to-r from-dark-orange to-phoenix-orange text-white">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
)

export default ContactUs
