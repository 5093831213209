export interface StatisticsInterface {
  title: string
  description: string
  stats: StatisticsInterface[]
}

export interface StatisticsInterface {
  title: string
  value: string
}

export default {
  title: 'Delivered success to customers',
  description: 'Achieve real results, just like our clients',
  stats: [
    {
      title: 'Increase in total stakes',
      value: '+37%'
    },
    {
      title: 'Uplift in turnover',
      value: '+20%'
    },
    {
      title: 'Decrease in churn rate',
      value: '+15%'
    }
  ]
} as StatisticsInterface
