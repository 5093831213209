import React from 'react'

import { type ProductsDataInterface } from '@/public/content/products_data'
import { GoArrowRight } from 'react-icons/go'
import Image from 'next/image'

const Products: React.FC<ProductsDataInterface> = ({ title, description, products }) => (
  <div className="flex flex-col min-h-screen items-center justify-between pb-8 z-10 products">
    <div className="container text-left">
      <div className="flex flex-col space-y-[5vh]">
        {products.map((product, index) => (
          <div
            key={index}
            // data-aos={index % 2 == 0 ? 'fade-left' : 'fade-right'}
            className={`flex items-center justify-center flex-col w-[90%] md:w-2/3 mx-auto bg-gradient-to-b from-black/5 to-black/30 p-6 rounded-lg`}
            style={{ boxShadow: '0px -2px 10px 0px #e9dfff1a' }}
          >
            <div className="w-full"></div>
            <div className="w-full text-white p-6 space-y-2">
              <div
                className="p-2 bg-gradient-to-b from-accent/80 to-accent/60 inline-flex justify-center aspect-square items-center rounded-lg border border-accent"
                style={{
                  boxShadow: '0px 0px 20px 0px #1976D2cc'
                }}
              >
                <Image src={product.image} alt={product.name} className="h-6 w-6 md:h-8 md:w-8" />
              </div>
              <h2 className="text-2xl md:text-3xl">{product.name}</h2>
              <p className="text-sm">{product.description}</p>
              <ul>
                {product.elements.map((feature, index) => (
                  <li key={index} className="flex items-center space-x-2">
                    <GoArrowRight className="text-accent" />
                    <p className="text-sm">{feature}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default Products
