import React from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  children: React.ReactNode
  className?: string
}

const CategoryTitle: React.FC<Props> = ({ children, className = '' }) => (
  <h2 className={twMerge('text-center text-2xl md:text-[42px] my-2', className)}>
    {children}
  </h2>
)

export default CategoryTitle
