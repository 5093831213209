import React from 'react'

import { type LandingDataInterface } from '@/public/content/landing_data'
import CardsImage from '@/public/images/background/cards.webp'
import { scrollToElementWithOffset } from '@/src/helpers'

const Landing: React.FC<LandingDataInterface> = ({ title, description }) => {
  const exists = typeof document !== 'undefined' && typeof window !== 'undefined'
  const products = exists && document.getElementsByClassName('products')[0]
  const contact = exists && document.getElementsByClassName('contact-us')[0]

  return (
    <div className="flex flex-col min-h-screen items-center justify-between pt-40 pb-8 z-10 landing relative">
      <div className="container text-left">
        <div className="h-full mx-2 items-center flex flex-col justify-center">
          <h1 className="text-white font-medium text-4xl md:text-6xl text-center">{title}</h1>
          <p className="text-white py-4 w-full lg:w-1/3 opacity-80 text-center">{description}</p>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => scrollToElementWithOffset(contact)}
              className="bg-accent p-4 uppercase text-white rounded-lg"
            >
              Contact us
            </button>
            <button
              onClick={() => scrollToElementWithOffset(products)}
              className="bg-black/50 hover:bg-black/60 transition-all duration-500 border border-phoenix-orange/30 p-3 uppercase text-white rounded-lg"
            >
              Learn more
            </button>
          </div>
          <div
            style={{
              backgroundImage: `url(${CardsImage.src})`,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              zIndex: -2
            }}
          />
          <div
            style={{ zIndex: -1 }}
            className="bg-gradient-to-b from-10% from-primary via-65% via-primary/70 to-primary absolute top-0 left-0 right-0 bottom-0"
          ></div>
        </div>
      </div>
    </div>
  )
}

export default Landing
