const SCROLL_OFFSET = 175

export function scrollToElementWithOffset(element: any) {
  const exists = typeof document !== 'undefined' && typeof window !== 'undefined'
  if (!exists || !element) return

  const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
  const offsetPosition = elementPosition - SCROLL_OFFSET

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}
