import { AboutInterface } from '@/public/content/about_data'
import * as React from 'react'

interface Props {
  data: AboutInterface
}

const AboutSection: React.FC<Props> = ({ data }) => (
  <div data-aos="fade-left" className="w-full md:w-2/3 mx-auto flex flex-col items-center about">
    <div className="w-full space-y-2 mx-2 items-center flex flex-col justify-center mb-12 p-6">
      <h1 className="text-white font-medium text-3xl md:text-5xl">{data.title}</h1>
    </div>
    <div className="flex flex-col md:flex-row space-y-12 md:space-y-0 md:space-x-12 w-full p-6">
      {data?.sections?.map((section, index) => (
        <div className="flex flex-col space-y-12 w-full" key={index}>
          <h2 className="text-3xl text-white">{section.title}</h2>
          <div className="text-white/40">{section.description}</div>
        </div>
      ))}
    </div>
  </div>
)

export default AboutSection
