export interface AboutInterface {
  title: string
  sections: SectionInterface[]
}

export interface SectionInterface {
  title: string
  description: JSX.Element
}

export default {
  title: 'About',
  sections: [
    {
      title: 'Our story',
      description: (
        <>
          <p>
            Gambly.AI was founded by industry veterans to modernize the betting and gaming sector
            with AI and analytics tools. Built for iGaming experts, by experts, Gambly.AI enables
            operators to navigate the fast-evolving landscape with unique efficiency and innovation.
            We are committed to transforming the industry, helping operators lead with smarter,
            safer, and more personalized solutions.
          </p>
        </>
      )
    },
    {
      title: 'Our vision',
      description: (
        <p>
          At Gambly.AI, our vision is to reshape the iGaming industry by bridging the gap between
          innovation and insight. We empower operators with advanced analytics and AI-driven tools
          that unlock meaningful player and business insights while ensuring data privacy. By
          leveraging latest technology and expertise, we aim to transform the industry into a
          smarter, more engaging, and high-performing ecosystem.
        </p>
      )
    }
  ]
} as AboutInterface
