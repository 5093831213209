import React from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  children: React.ReactNode
  className?: string
}

const CategoryDescription: React.FC<Props> = ({ children, className = '' }) => (
  <p
    className={twMerge(
      'text-center text-[17px] opacity-90 font-thin my-2',
      className
    )}
  >
    {children}
  </p>
)

export default CategoryDescription
