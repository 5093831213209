import RiskImage from '@/public/images/icons/risk.svg'
import DataImage from '@/public/images/icons/data.svg'
import EngagementImage from '@/public/images/icons/engagement.svg'
import { StaticImageData } from 'next/image'

export interface ProductsDataInterface {
  title: string
  description: string
  products: ProductInterface[]
}

interface ProductInterface {
  name: string
  description: string
  image: StaticImageData
  elements: string[]
}

export default {
  title: 'Solutions to unleash your true iGaming potential',
  description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
  products: [
    {
      name: 'Player Engagement',
      description:
        'Discover the power of AI and machine learning to transform your betting and gambling operations. Our advanced platform equips you with tools to enhance player experiences, drive engagement, and maximize lifetime value. Here is how we can help:',
      image: EngagementImage,
      elements: [
        "Netflix-like game suggestions: Deliver personalized game recommendations tailored to each player's preferences.",
        'ML-driven analytics: Proactively reduce churn risk with machine learning-powered insights.',
        '100% data-driven CRM Support: Empower your CRM teams with actionable, data-driven decision-making tools.'
      ]
    },
    {
      name: 'Risk Management',
      description:
        'Unlock the potential of advanced analytics and automation to revolutionize your trading and risk management processes. Our platform delivers exceptional insights and efficiency, helping you stay ahead in the competitive betting and gambling industry. We offer:',
      image: RiskImage,
      elements: [
        'Real-time odds monitoring: Stay informed with real-time odds alerts and updates to optimize decision-making.',
        'Automated user profiling: Detect arbitrage opportunities and identify sharp players with precision.',
        'Predictive risk analytics: Leverage advanced statistical tools and predictive insights to enhance trading strategies.'
      ]
    },
    {
      name: 'Data Warehouse as a Service (DWHaaS)',
      description:
        'Elevate your operations with advanced tools and a flexible infrastructure designed to enhance efficiency and drive results. Our comprehensive DWHaaS solution handles the complexities of building and operating robust data platforms to optimize performance. We provide:',
      image: DataImage,
      elements: [
        'Aggregated metrics for insights: Combine player and trading data for actionable insights.',
        'Tailored modeling and analytics: Address specific business challenges with flexible reports.',
        'Customizable dashboards: Track performance and refine strategies with fully adaptable dashboards.'
      ]
    }
  ]
} as ProductsDataInterface
