import * as React from 'react'

import { StatisticsInterface } from '@/public/content/statistics_data'

interface Props {
  title: string
  description: string
  stats: StatisticsInterface[]
}

const StatisticsSection: React.FC<Props> = ({ title, description, stats }) => {
  return (
    <div className="flex flex-col space-y-4 text-center text-white">
      <h1 className="text-white font-medium text-3xl md:text-5xl">{title}</h1>
      <p>{description}</p>
      <div className="w-full gap-8 md:gap-0 grid grid-cols-1 md:grid-cols-3 solutions md:w-2/3 mx-auto space-y-2">
        {stats?.map((question, index) => (
          <div key={index} className="flex flex-col items-center justify-center space-y-2">
            <h1 className="text-white text-5xl font-bold">{question.value}</h1>
            <h3 className="text-white">{question.title}</h3>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StatisticsSection
